<template>
  <sign-page
    title-text="薪级总览"
    :request="request"
    table-size="large"
    :search-parms="searchParms"
    :initSearchData="{year: $datetime.format(new Date(), 'Y')}"
    @clickTitleMenu="clickTitleMenu"
    :titleMenus="[{key: 'batch_up', label: '智能升薪'},{key: 'batch', label: '批量调薪'}]"
    :column-list="columnList"
    :tableActions="[]"
    :dealSearchParm="dealSearchParm"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
    <wage-form
      @dataUpdate="dataUpdate"
      :columnList="columnList"
      valueKey="wageLevelLevel"
      :formatValue="formatValue"
      type="wageLevel"
      changeWageLevelType="up"
      :workerList="workers"
      :wageLevelList="wageLeves"
      ref="wafeForm_up"
      title="智能升薪"></wage-form>
    <wage-form
      @dataUpdate="dataUpdate"
      :columnList="columnList"
      valueKey="wageLevelLevel"
      :formatValue="formatValue"
      type="wageLevel"
      :workerList="workers"
      :wageLevelList="wageLeves"
      ref="wafeForm"
      title="批量调薪"></wage-form>
  </sign-page>
</template>

<script>
import {
  workerWageRequest as request,
  wageLevelRequest,
  performanceWorkerRequest
} from '@/api'
import WageForm from './cmp/form'
import { number } from 'echarts'

export default {
  components: {
    WageForm
  },
  created () {
    this.loadWorker()
    this.loadWageLevel()
  },
  methods: {
    formatValue (data) {
      return data.wageLevelId ? (data.wageLevelLevel ? data.wageLevelLevel + '级' : '') : '无'
    },
    async loadData (parm) {
      let data = await request.get(parm)
      let dataMap = {}
      data.forEach(v => {
        if (!dataMap[v.performanceWorkerId]) {
          dataMap[v.performanceWorkerId] = {
            workerName: v.workerName
          }
        }
        dataMap[v.performanceWorkerId]['wageLevelLevel_' + Number(v.month.slice(5))] = v.wageLevelLevel
      })
      return Object.keys(dataMap).map(key => dataMap[key])
    },
    dataUpdate () {
      this.pageVm.loadData()
    },
    async loadWageLevel () {
      let datas = await wageLevelRequest.get({})
      this.wageLeves = datas.map(v => {
        return {
          key: v.id,
          label: v.jobTitleType + ' ' + v.name,
          data: v
        }
      })
    },
    dealSearchParm (parm) {
      if (parm) {
        parm.year = parm.year && parm.year instanceof Date ? this.$datetime.format(parm.year, 'Y') : parm.year
      }
      return parm
    },
    clickTitleMenu (key, pageVm) {
      this.pageVm = pageVm
      if (key === 'batch') {
        this.$refs.wafeForm.open()
      } else if (key === 'batch_up') {
        this.$refs.wafeForm_up.open()
      }
    },
    async loadWorker () {
      let datas = await performanceWorkerRequest.get({})
      this.workers = datas.map(v => {
        return {
          key: v.id,
          label: v.name,
          data: v
        }
      })
    }
  },
  computed: {
    searchParms () {
      return [{
        type: 'yearPicker',
        placeholder: '年份',
        key: 'year',
        default: new Date()
      },
      {
        type: 'input',
        placeholder: '姓名',
        key: 'likeWorkerName'
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '',
          fixed: 'left',
          width: 100,
          field: 'workerName'
        }]
        let i = 1
        while (i < 13) {
          let j = i
          data.push({
            title: i + '月',
            field: 'wageLevelLevel_' + i,
            sort: true,
            type: number,
            render: (h, rowData) => {
              return h('div', rowData['wageLevelLevel_' + j] ?  (rowData['wageLevelLevel_' + j] + '级') : '-')
            }
          })
          i += 1
        }
        return data
      }
    }
  },
  data () {
    return {
      workers: [],
      wageLeves: [],
      request: {
        get: this.loadData
      }
    }
  }
}
</script>
